import { __assign } from "tslib";
import React from 'react';
import { useEditorState } from '../editor/EditorContext';
export function Separator(props) {
    var editorState = useEditorState();
    if (editorState.htmlMode) {
        return null;
    }
    return React.createElement("div", __assign({ className: "rsw-separator" }, props));
}
