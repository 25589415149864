export function autoconfigureTextDirection(el) {
    if (el) {
        var text = el.textContent;
        var rtlPattern = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
        el.style.direction = text && rtlPattern.test(text[0]) ? 'rtl' : 'ltr';
    }
}
export function cls() {
    var classNames = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classNames[_i] = arguments[_i];
    }
    return classNames.filter(Boolean).join(' ');
}
export function getSelectedNode() {
    if (document.selection) {
        return document.selection.createRange().parentElement();
    }
    var selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
        return selection.getRangeAt(0).startContainer.parentNode || undefined;
    }
    return undefined;
}
export function normalizeHtml(str) {
    return str
        ? str.replace(/&nbsp;|\u202F|\u00A0/g, ' ').replace(/<br \/>/g, '<br>')
        : '';
}
export function replaceCaret(el) {
    // Place the caret at the end of the element
    var target = document.createTextNode('');
    el.appendChild(target);
    // do not move caret if element was not focused
    var isTargetFocused = document.activeElement === el;
    if (target !== null && target.nodeValue !== null && isTargetFocused) {
        var sel = window.getSelection();
        if (sel !== null) {
            var range = document.createRange();
            range.setStart(target, target.nodeValue.length);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }
        if (el instanceof HTMLElement)
            el.focus();
    }
}
export function setForwardRef(el, ref) {
    if (typeof ref === 'function') {
        ref(el);
    }
    else if (typeof ref === 'object' && ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = el;
    }
}
