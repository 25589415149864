import { __assign } from "tslib";
import React from 'react';
export var EditorContext = React.createContext(undefined);
export function EditorProvider(_a) {
    var children = _a.children;
    var _b = React.useState({
        htmlMode: false,
        update: update,
    }), state = _b[0], setState = _b[1];
    function update(attrs) {
        setState(function (prevState) {
            return __assign(__assign({}, prevState), attrs);
        });
    }
    return (React.createElement(EditorContext.Provider, { value: state }, children));
}
export function useEditorState() {
    var context = React.useContext(EditorContext);
    if (!context) {
        throw new Error('You should wrap your component by EditorProvider');
    }
    return context;
}
