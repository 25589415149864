import { __assign } from "tslib";
import React from 'react';
import { useEditorState } from '../editor/EditorContext';
import OrderedListIcon from './icons/OrderedListIcon';
import UnorderedListIcon from './icons/UnorderedListIcon';
export var BtnBold = createButton('Bold', '𝐁', 'bold');
export var BtnBulletList = createButton('Bullet list', React.createElement(UnorderedListIcon, null), 'insertUnorderedList');
export var BtnClearFormatting = createButton('Clear formatting', 'T̲ₓ', 'removeFormat');
export var BtnItalic = createButton('Italic', '𝑰', 'italic');
export var BtnStrikeThrough = createButton('Strike through', React.createElement("s", null, "ab"), 'strikeThrough');
export var BtnLink = createButton('Link', '🔗', function (_a) {
    var $selection = _a.$selection;
    if (($selection === null || $selection === void 0 ? void 0 : $selection.nodeName) === 'A') {
        document.execCommand('unlink');
    }
    else {
        // eslint-disable-next-line no-alert
        document.execCommand('createLink', false, prompt('URL', '') || undefined);
    }
});
export var BtnNumberedList = createButton('Numbered list', React.createElement(OrderedListIcon, null), 'insertOrderedList');
export var BtnRedo = createButton('Redo', '↷', 'redo');
export var BtnUnderline = createButton('Underline', React.createElement("span", { style: { textDecoration: 'underline' } }, "\uD835\uDC14"), 'underline');
export var BtnUndo = createButton('Undo', '↶', 'undo');
export function createButton(title, content, command) {
    ButtonFactory.displayName = title.replace(/\s/g, '');
    return ButtonFactory;
    function ButtonFactory(props) {
        var editorState = useEditorState();
        var $el = editorState.$el, $selection = editorState.$selection;
        var active = false;
        if (typeof command === 'string') {
            active = !!$selection && document.queryCommandState(command);
        }
        function onAction(e) {
            e.preventDefault();
            if (document.activeElement !== $el) {
                $el === null || $el === void 0 ? void 0 : $el.focus();
            }
            if (typeof command === 'function') {
                command(editorState);
            }
            else {
                document.execCommand(command);
            }
        }
        if (editorState.htmlMode) {
            return null;
        }
        return (React.createElement("button", __assign({ className: "rsw-btn", "data-active": active, onMouseDown: onAction, tabIndex: -1, title: title, type: "button" }, props), content));
    }
}
