import { __assign, __rest } from "tslib";
import React from 'react';
import { useEditorState } from '../editor/EditorContext';
export function HtmlButton(_a) {
    var rest = __rest(_a, []);
    var editorState = useEditorState();
    function onClick() {
        editorState.update({
            htmlMode: !editorState.htmlMode,
        });
    }
    return (React.createElement("button", __assign({ className: "rsw-btn", "data-active": editorState.htmlMode, onClick: onClick, tabIndex: -1, title: "HTML mode", type: "button" }, rest), "</>"));
}
