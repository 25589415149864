import { __assign, __rest } from "tslib";
import React from 'react';
import { cls, getSelectedNode, setForwardRef } from '../utils';
import { ContentEditable } from './ContentEditable';
import { useEditorState } from './EditorContext';
import '../styles.css';
export var Editor = React.forwardRef(function Editor(_a, ref) {
    var autoFocus = _a.autoFocus, children = _a.children, containerProps = _a.containerProps, onSelect = _a.onSelect, rest = __rest(_a, ["autoFocus", "children", "containerProps", "onSelect"]);
    var editorState = useEditorState();
    React.useEffect(function () {
        document.addEventListener('click', onClickOutside);
        return function () { return document.removeEventListener('click', onClickOutside); };
    });
    function onClickOutside(event) {
        var _a;
        if (event.target === editorState.$el) {
            return;
        }
        if ((_a = editorState.$el) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
            return;
        }
        editorState.update({ $selection: undefined });
    }
    function onTextSelect(event) {
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(event);
        editorState.update({ $selection: getSelectedNode() });
    }
    function setContentEditableRef($el) {
        editorState.update({ $el: $el });
        setForwardRef($el, ref);
        if (autoFocus && $el && editorState.$el === undefined) {
            $el.focus();
        }
    }
    var cssClass = cls('rsw-editor', containerProps === null || containerProps === void 0 ? void 0 : containerProps.className);
    if (editorState.htmlMode) {
        return (React.createElement("div", __assign({}, containerProps, { className: cssClass }),
            children,
            React.createElement("textarea", __assign({}, rest, { className: "rsw-ce rsw-html" }))));
    }
    return (React.createElement("div", __assign({}, containerProps, { className: cssClass }),
        children,
        React.createElement(ContentEditable, __assign({}, rest, { ref: setContentEditableRef, onSelect: onTextSelect }))));
});
