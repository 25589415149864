import { __assign, __rest } from "tslib";
import React from 'react';
import { autoconfigureTextDirection, cls, normalizeHtml, replaceCaret, setForwardRef, } from '../utils';
/**
 * Based on https://github.com/lovasoa/react-contenteditable
 * A simple component for a html element with editable contents.
 */
export var ContentEditable = React.memo(React.forwardRef(function ContentEditable(_a, ref) {
    var 
    // Some properties are used here only as useMemo dependencies
    className = _a.className, disabled = _a.disabled, tagName = _a.tagName, _b = _a.value, value = _b === void 0 ? '' : _b, placeholder = _a.placeholder, rest = __rest(_a, ["className", "disabled", "tagName", "value", "placeholder"]);
    var elRef = React.useRef(null);
    var htmlRef = React.useRef(value);
    var restRef = React.useRef(rest);
    React.useEffect(function () {
        restRef.current = rest;
        var el = elRef.current;
        if (el && normalizeHtml(htmlRef.current) !== normalizeHtml(value)) {
            htmlRef.current = value;
            el.innerHTML = value;
            replaceCaret(el);
        }
    });
    return React.useMemo(function () {
        function onSetRef($el) {
            elRef.current = $el;
            autoconfigureTextDirection($el);
            setForwardRef($el, ref);
        }
        function onChange(event) {
            var _a, _b;
            var el = elRef.current;
            if (!el) {
                return;
            }
            var elementHtml = el.innerHTML;
            if (elementHtml !== htmlRef.current) {
                (_b = (_a = restRef.current).onChange) === null || _b === void 0 ? void 0 : _b.call(_a, __assign(__assign({}, event), { target: {
                        value: elementHtml,
                        name: rest.name,
                    } }));
            }
            autoconfigureTextDirection(el);
            htmlRef.current = elementHtml;
        }
        var cssClass = cls('rsw-ce', className);
        return React.createElement(tagName || 'div', __assign(__assign({}, rest), { className: cssClass, contentEditable: !disabled, dangerouslySetInnerHTML: { __html: value }, onBlur: function (e) {
                return (restRef.current.onBlur || onChange)(e);
            }, onInput: onChange, onKeyDown: function (e) {
                return (restRef.current.onKeyDown || onChange)(e);
            }, onKeyUp: function (e) {
                return (restRef.current.onKeyUp || onChange)(e);
            }, placeholder: placeholder, ref: onSetRef }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [className, disabled, placeholder, tagName]);
}));
