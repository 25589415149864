import { __assign } from "tslib";
import React from 'react';
import { Editor } from './Editor';
import { EditorProvider } from './EditorContext';
import { BtnBold, BtnBulletList, BtnClearFormatting, BtnItalic, BtnLink, BtnNumberedList, BtnRedo, BtnStrikeThrough, BtnStyles, BtnUnderline, BtnUndo, HtmlButton, Separator, Toolbar, } from '../toolbar';
export var DefaultEditor = React.forwardRef(function DefaultEditor(props, ref) {
    return (React.createElement(EditorProvider, null,
        React.createElement(Editor, __assign({}, props, { ref: ref }), props.children || (React.createElement(Toolbar, null,
            React.createElement(BtnUndo, null),
            React.createElement(BtnRedo, null),
            React.createElement(Separator, null),
            React.createElement(BtnBold, null),
            React.createElement(BtnItalic, null),
            React.createElement(BtnUnderline, null),
            React.createElement(BtnStrikeThrough, null),
            React.createElement(Separator, null),
            React.createElement(BtnNumberedList, null),
            React.createElement(BtnBulletList, null),
            React.createElement(Separator, null),
            React.createElement(BtnLink, null),
            React.createElement(BtnClearFormatting, null),
            React.createElement(HtmlButton, null),
            React.createElement(Separator, null),
            React.createElement(BtnStyles, null))))));
});
